import {Component} from "react";
import React from "react";

class CryptoIcon extends Component {
    constructor(props) {
        /*
            Props:
             - cryptocurrency
         */
        super(props);

    }

    render() {
        return (
            <img className="ml-1" src={"icons/cryptocurrencies/"+this.props.cryptocurrency+"_24.png"}/>
        )
    }
}

export default CryptoIcon;
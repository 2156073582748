import React, { Component } from 'react';
import './App.css';
import ValidatedFormComponent from "./components/ValidatedFormComponent";
import Form from "react-bootstrap/lib/Form";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import request from "request-promise-native";
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import Alert from "react-bootstrap/lib/Alert";
import QRCode from "qrcode.react";
import {Row} from "react-bootstrap";
import CryptoIcon from "./components/CryptoIcon";

const BASE_URL = "https://thundrpay.azurewebsites.net";
//const BASE_URL = "http://127.0.0.1:7071";
const BACKEND_GET_INFO = BASE_URL+"/api/InvoiceGetInfo";
const BACKEND_GET_PAYMENT = BASE_URL+"/api/PaymentGet";
const BACKEND_INIT_PAYMENT = BASE_URL+"/api/InvoicePaymentInit";
const SIGNALR_URL = BASE_URL+"/api";

const dictionary = {
    "LTC": "Litecoin",
    "BTC": "Bitcoin",
    "BTC-LN": "Bitcoin lightning network",
    "BTC-LNL": "Bitcoin lightning network (LEGACY)",
};


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: null,
            loading: true,
            submitting: false,

            success: false,
            failed: false,
        };

    }

    async componentDidMount() {

        window.onpopstate = (event) => {
            console.log("popstate: ",event);
            window.location.reload();
        };

        this.queryDict = {};
        window.location.search.substr(1).split("&").forEach(function (item) {
            this.queryDict[item.split("=")[0]] = item.split("=")[1]
        }.bind(this));

        if (this.queryDict["i"] != null) {
            this.invoice = this.queryDict["i"];

            this.setState({
                loading: true
            });

            const options = {
                method: 'POST',
                uri: BACKEND_GET_INFO,
                body: {
                    invoice: this.invoice
                },
                json: true,
                resolveWithFullResponse: true,
                simple: false
            };

            const response = await request(options);

            if (response.statusCode === 200) {
                if (response.body.code === 10000) {
                    this.setState({
                        loading: false,
                        success: null,
                        supportedCurrencies: response.body.data.supportedCurrencies,
                        currency: response.body.data.currency,
                        amount: response.body.data.amount
                    });
                }
                if (response.body.code === 10024) {
                    this.setState({
                        loading: false,
                        success: "Invoice is already paid for!"
                    });
                }
            } else if (response.statusCode === 400) {
                if (response.body.msg != null) {
                    this.setState({
                        loading: false,
                        failed: response.body.msg
                    });
                } else {
                    this.setState({
                        loading: false,
                        failed: "Error getting invoice"
                    });
                }
            } else {
                this.setState({
                    loading: false,
                    failed: "Error getting invoice"
                });
            }
        } else if(this.queryDict["p"] != null) {
            this.payment = this.queryDict["p"];

            this.setState({
                loading: true
            });

            const options = {
                method: 'POST',
                uri: BACKEND_GET_PAYMENT,
                body: {
                    paymentId: this.payment
                },
                json: true,
                resolveWithFullResponse: true,
                simple: false
            };

            const response = await request(options);

            if (response.statusCode === 200) {
                if (response.body.code === 10000) {
                    this.submitConnectSignalR(response);
                }
                if (response.body.code === 10026) {
                    this.setState({
                        loading: false,
                        success: "Payment already received!"
                    });
                }
            } else if (response.statusCode === 400) {
                this.setState({
                    loading: false,
                    failed: response.body!=null && response.body.msg!=null ? response.body.msg : "Error fetching payment"
                });
            } else {
                this.setState({
                    loading: false,
                    failed: "Error fetching payment"
                });
            }
        } else {
            this.setState({
                loading: false,
                failed: "Invalid link"
            });
        }
    }

    async submitConnectSignalR(response) {

        Object.defineProperty(WebSocket, 'OPEN', { value: 1, });

        this.connection = new window.signalR.HubConnectionBuilder()
            .withUrl(SIGNALR_URL+"?paymentId="+response.body.data.paymentId)
            .withAutomaticReconnect()
            .configureLogging(window.signalR.LogLevel.Trace)
            .build();

        this.connection.on("txResult", (success) => {
            if(success) {
                this.setState({
                    success: "Invoice paid successfully!"
                });
            } else {
                this.setState({
                    success: "Invoice payment failed!"
                });
            }
            this.connection.stop();
            this.connection = null;
            if(this.interval!=null) {
                clearInterval(this.interval);
                delete this.interval;
            }
        });

        await this.connection.start();

        this.setState({
            loading: false,
            success: null,
            paymentLink: response.body.data.link,
            address: response.body.data.address,
            amount: response.body.data.amount,
            cryptocurrency: response.body.data.cryptocurrency,

            totalPayWindow: response.body.data.totalPayWindow,
            expiry: response.body.data.expiry-response.body.data.serverTime+Date.now(),
            currentTime: Date.now()
        });

        this.interval = setInterval(() => {
            const currentTime = Date.now();
            if(currentTime>this.state.expiry) {
                if(this.connection!=null) {
                    this.connection.stop();
                    this.connection = null;
                }
                clearInterval(this.interval);
                delete this.interval;
                this.setState({
                    failed: "Payment expired!"
                });
            } else {
                this.setState({
                    currentTime
                });
            }
        }, 1000);

    }

    async submit(currency) {

        this.setState({
            loading: true
        });

        const options = {
            method: 'POST',
            uri: BACKEND_INIT_PAYMENT,
            body: {
                invoice: this.invoice,
                cryptocurrency: currency
            },
            json: true,
            resolveWithFullResponse: true,
            simple: false
        };

        const response = await request(options);

        if (response.statusCode === 200) {
            if (response.body.code === 10000) {

                window.history.pushState("p="+response.body.data.paymentId,"","?p="+response.body.data.paymentId);
                await this.submitConnectSignalR(response);

            }
            if (response.body.code === 10024) {
                this.setState({
                    loading: false,
                    success: "Invoice is already paid for!"
                });
            }
        } else if (response.statusCode === 400) {
            if (response.body.msg != null) {
                this.setState({
                    loading: false,
                    failed: response.body.msg
                });
            } else {
                this.setState({
                    loading: false,
                    failed: "Error initializing invoice payment"
                });
            }
        } else {
            this.setState({
                loading: false,
                failed: "Error initializing invoice payment"
            });
        }

    }

    copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    toHumanReadableTime(millis) {
        let seconds = Math.floor(millis/1000);
        let secondsTill60 = seconds%60;
        let minutes = Math.floor(seconds/60);

        return minutes+":"+("0" + secondsTill60).slice(-2);
    }

    render() {
      return (
                  <div className="content-div center">
                      <img className="logo" style={{
                          height: 200,
                          objectFit: "contain"
                      }} src="hopa-logo-white.png" alt="Hopa" title="Hopa"/>
                      {this.state.failed ? (<Alert bsStyle="danger">
                          <strong>{this.state.failed}</strong>
                      </Alert>) : this.state.success ? (<Alert bsStyle="success">
                          <strong>{this.state.success}</strong>
                      </Alert>) : this.state.loading ? (
                          <div>
                              <br/>
                              <Col xs={1}/>
                              <Col xs={10}>
                                  <ProgressBar bsStyle="success" active now={100}/>
                              </Col>
                              <Col xs={1}/>
                              <br/>
                          </div>
                      ) : this.state.paymentLink!=null ? (
                          <div style={{
                              textAlign: "center"
                          }}>
                              <QRCode
                                  value={this.state.paymentLink}
                                  size={300}
                                  includeMargin={true}
                              />

                              <h4>Please pay in {this.toHumanReadableTime(this.state.expiry-this.state.currentTime)} minutes...</h4>
                              <ProgressBar bsStyle="primary" active now={(1-((this.state.expiry-this.state.currentTime)/this.state.totalPayWindow))*100}/>

                              <Row>
                                  <Col xs={2} />
                                  <Col xs={8} componentClass={Button} className="btn-success" onClick={() => {
                                      window.location.replace(this.state.paymentLink);
                                  }}>
                                      Open in app
                                  </Col>
                                  <Col xs={2} />
                              </Row>
                              <Row>
                                  <Col xs={2} />
                                  <Col xs={8} componentClass={Button} className="btn-primary" onClick={() => {
                                      this.copyToClipboard(this.state.paymentLink)
                                  }}>
                                      Copy to clipboard
                                  </Col>
                                  <Col xs={2} />
                              </Row>
                              {this.state.address!=null && this.state.amount!=null ? (
                                  <div>
                                      <h5>{this.state.address}</h5>
                                      <h6>{this.state.amount} {this.state.cryptocurrency}</h6>
                                  </div>
                              ) : ""}
                          </div>
                      ) : (<Form horizontal style={{
                          textAlign: "center"
                      }}>
                          <h4>Amount due</h4>
                          <h2><b>{this.state.amount}</b> {this.state.currency.toUpperCase()}</h2>

                          <h3>Pay via</h3>

                          {(() => {
                              const arr = [];

                              for(let currency of this.state.supportedCurrencies) {
                                  arr.push((
                                      <Row style={{
                                          marginTop: "8px",
                                          marginBottom: "8px"
                                      }}>
                                          <Col xs={1} />
                                          <Col xs={10} componentClass={Button} disabled={this.state.loading} key={currency} onClick={() => {
                                              this.submit(currency);
                                          }}>
                                              <CryptoIcon cryptocurrency={currency}/> <b>{dictionary[currency]}</b>
                                          </Col>
                                          <Col xs={1} />
                                      </Row>
                                  ))
                              }

                              return arr;
                          })()}

                          {this.state.supportedCurrencies.includes("BTC-LN") ? (
                              <Alert bsStyle="info" style={{
                                  marginTop: "20px"
                              }}>
                                  In case <CryptoIcon cryptocurrency={"BTC-LN"}/> <b>{dictionary["BTC-LN"]}</b> doesn't work for you, try using legacy lightning: <CryptoIcon cryptocurrency={"BTC-LNL"}/> <b>{dictionary["BTC-LNL"]}</b>
                              </Alert>
                          ) : ""}

                      </Form>)}
                  </div>
      );
  }
}

/*<ValidatedFormComponent name="Cryptocurrency"
                        type="select"
                        onValidate={input => {
                            if(input==="") return "Invoice number cannot be empty!"
                        }}
                        options={this.state.supportedCurrencies!=null ? this.state.supportedCurrencies.map((e) => {
                            return {
                                value: e,
                                display_name: e
                            }
                        }) : []}
                        value_key={"value"}
                        display_name_key={"display_name"}
                        inputRef={ref => this.currencyRef= ref}/>*/

/*<div>
    <Col xs={2} />
    <Col xs={8} componentClass={Button} className="btn-success" disabled={this.state.loading} onClick={this.submit.bind(this)}>
        Pay{this.state.amount===null ? "" : " "+this.state.amount+" "+this.state.currency.toUpperCase()}!
    </Col>
    <Col xs={2} />
</div>*/

export default App;

import {Component} from 'react';
import React from 'react';
import App from "./App";


class AppWrapper extends Component {
    constructor(props) {
        /*
        
        */
        super(props);
    }
    
    render() {
        return (
            <App/>
        );
    }
}

export default AppWrapper;
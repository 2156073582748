import {Component} from 'react';
import React from 'react';
import FormGroup from "react-bootstrap/lib/FormGroup";
import Col from "react-bootstrap/lib/Col";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import Switch from "react-switch";

class ValidatedFormComponent extends Component {

    constructor(props) {
        /*
            Props:
             - onValidate (function(input))
             - inputRef (function(ref)) - ref contains .validated() function to determine if input is validated OK
             - onChange (function(value))

             - bsSize

             - value
             - disableFeedback

             - name
             - type
             - placeholder

             #IF type=='select'
                - value_key
                - display_name_key
                - options
                  - value
                  - display_name
             #ENDIF

             #IF type=='number'
                - emptyAllowed
                - min
                - max
                - step
                - decimal
             #ENDIF

         */
        super(props);

        if(this.props.onValidate==null) {
            this.props.onValidate = function() {
                return null;
            }
        }

        if(this.props.type==='boolean') {
            this.state = {
                value: props.value==null ? 'false' : props.value,
                errMsg: null
            };
            if(this.props.inputRef!=null) {
                const ref = {
                    validated: this.validated.bind(this),
                    value: this.state.value
                }
                this.props.inputRef(ref);
            }
            return;
        }

        this.state = {
            value: props.value==null ? '' : props.value,
            errMsg: null
        };
    }

    validated() {
        if(this.props.type==='number') {
            const numValid = this.numberValidator(this.state.value);
            if(numValid!=null) {
                this.setState({
                    errMsg: numValid
                });
                return false;
            }
        }
        const errMsg = this.props.onValidate(this.state.value);
        this.setState({
            errMsg: errMsg
        })
        return errMsg==null;
    }

    numberValidator(value) {
        if(this.props.emptyAllowed && value==='') return null;
        if(value==='') return 'Value cannot be empty!';
        const val = this.props.decimal ? parseFloat(value) : parseInt(value);
        console.log("Nan?: "+val);
        if(isNaN(val)) return 'Value is not a number!';
        if(this.props.min!=null && val<this.props.min) return 'Value cannot be lower than '+this.props.min+'!';
        if(this.props.max!=null && val>this.props.max) return 'Value cannot be higher than '+this.props.max+'!';
        return null;
    }

    //TODO: More to add...

    render() {
        const errMsg = this.state.errMsg;
        const options = [];
        if(this.props.type==='select') {
            let i = 0;
            if(this.props.options!=null && this.props.value_key!=null && this.props.display_name_key!=null) for(let opt of this.props.options) {
                options.push(
                    (
                        <option key={'key'+i} value={opt[this.props.value_key]}>{opt[this.props.display_name_key]}</option>
                    )
                )
                i++;
            }
        }

        return (
            <FormGroup controlId={this.props.name} validationState={errMsg==null ? null : 'error'}>
                {this.props.name!=null ? (
                    <Col componentClass={ControlLabel} sm={4}>
                        {this.props.name}
                    </Col>
                    ) : ''}

                <Col sm={this.props.name!=null ? 8 : 12}>
                    {
                        this.props.type==='text' ?
                            (
                                <FormControl type="text" bsSize={this.props.bsSize} value={this.state.value} onChange={(evnt) => {
                                    const errMsg = this.props.onValidate(evnt.target.value);
                                    this.setState({
                                        value: evnt.target.value,
                                        errMsg: errMsg
                                    });
                                    if(this.props.onChange!=null && errMsg==null) this.props.onChange(evnt.target.value);
                                }} placeholder={this.props.placeholder} inputRef={ref => {
                                    if(ref==null || this.props.inputRef==null) return;
                                    ref.validated = this.validated.bind(this);
                                    this.props.inputRef(ref);
                                }} />
                            ) :
                        this.props.type==='select' ?
                            (
                                <FormControl componentClass="select" bsSize={this.props.bsSize} value={this.state.value} onChange={(evnt) => {
                                    const errMsg = this.props.onValidate(evnt.target.value);
                                    this.setState({
                                        value: evnt.target.value,
                                        errMsg: errMsg
                                    });
                                    if(this.props.onChange!=null && errMsg==null) this.props.onChange(evnt.target.value);
                                }} placeholder={this.props.placeholder} inputRef={ref => {
                                    if(ref==null || this.props.inputRef==null) return;
                                    ref.validated = this.validated.bind(this);
                                    this.props.inputRef(ref);
                                }} >
                                    {options}
                                </FormControl>
                            ) :
                        this.props.type==='password' ?
                            (
                                <FormControl type="password" bsSize={this.props.bsSize} value={this.state.value} onChange={(evnt) => {
                                    const errMsg = this.props.onValidate(evnt.target.value);
                                    this.setState({
                                        value: evnt.target.value,
                                        errMsg: errMsg
                                    });
                                    if(this.props.onChange!=null && errMsg==null) this.props.onChange(evnt.target.value);
                                }} placeholder={this.props.placeholder} inputRef={ref => {
                                    if(ref==null || this.props.inputRef==null) return;
                                    ref.validated = this.validated.bind(this);
                                    this.props.inputRef(ref);
                                }} />
                            ) :
                        this.props.type==='number' ?
                            (
                                <FormControl type="number"
                                             bsSize={this.props.bsSize}
                                             value={this.state.value}
                                             min={this.props.min}
                                             max={this.props.max}
                                             step={this.props.step}
                                             onChange={(evnt) => {
                                                 const numValid = this.numberValidator(evnt.target.value);
                                                 if(numValid!=null) {
                                                     this.setState({
                                                         value: evnt.target.value,
                                                         errMsg: numValid
                                                     });
                                                     return;
                                                 }
                                                 const errMsg = this.props.onValidate(evnt.target.value);
                                                 this.setState({
                                                     value: evnt.target.value,
                                                     errMsg: errMsg
                                                 });
                                                 if(this.props.onChange!=null && errMsg==null) this.props.onChange(evnt.target.value);
                                             }}
                                             placeholder={this.props.placeholder} inputRef={ref => {
                                                 if(ref==null || this.props.inputRef==null) return;
                                                 ref.validated = this.validated.bind(this);
                                                 this.props.inputRef(ref);
                                             }} />
                            ) :
                        this.props.type==='boolean' ?
                            (
                                <Switch
                                    onChange={(checked) => {
                                        const value = checked ? 'true' : 'false';
                                        if(this.props.inputRef!=null) {
                                            const ref = {
                                                validated: this.validated.bind(this),
                                                value: value
                                            }
                                            this.props.inputRef(ref);
                                        }
                                        const errMsg = this.props.onValidate(value);
                                        this.setState({
                                            value: value,
                                            errMsg: errMsg
                                        })
                                        if(this.props.onChange!=null && errMsg==null) this.props.onChange(value);
                                    }}
                                    checked={this.state.value==='true'}
                                    id={this.props.name}
                                />
                            ) : ''
                    }

                    {this.props.disableFeedback ? '' : (<FormControl.Feedback />)}

                    {errMsg!=null ? (<HelpBlock>{errMsg}</HelpBlock>) : ''}
                </Col>
            </FormGroup>
        );
    }
}

export default ValidatedFormComponent;